import Skeleton from 'react-loading-skeleton'

const SideNavLoading = () => {
  return (
    <div className="p-4">
      <Skeleton count={5} className="my-1 h-12" />
    </div>
  )
}

export default SideNavLoading
