import { createFragmentRegistry } from '@apollo/client/cache'
import { offsetLimitPagination } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import {
  POST_FIELDS_FRAGMENT,
  USER_PREVIEW_FIELDS_FRAGMENT,
  PROJECT_PREVIEW_FIELDS_FRAGMENT,
} from 'src/lib/fragments'
import FatalErrorPage from 'src/pages/Error/FatalErrorPage'

import { AuthProvider, useAuth } from './auth'

import './scaffold.css'
import './index.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  })
}

// Register named fragments using createFragmentRegistry
export const fragmentRegistry = createFragmentRegistry()
fragmentRegistry.register(POST_FIELDS_FRAGMENT)
fragmentRegistry.register(USER_PREVIEW_FIELDS_FRAGMENT)
fragmentRegistry.register(PROJECT_PREVIEW_FIELDS_FRAGMENT)

const App = ({ children }) => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <RedwoodApolloProvider
          useAuth={useAuth}
          graphQLClientConfig={{
            cacheConfig: {
              fragments: fragmentRegistry,
              typePolicies: {
                Query: {
                  fields: {
                    discoverPosts: {
                      ...offsetLimitPagination(),
                    },
                    engagementsByUser: {
                      ...offsetLimitPagination(),
                    },
                    latestPosts: {
                      ...offsetLimitPagination(),
                    },
                    postsByProjectSlug: {
                      ...offsetLimitPagination(),
                      keyArgs: ['organizationSlug', 'slug', 'categorySlug'],
                    },
                    postsByUser: {
                      ...offsetLimitPagination(),
                      keyArgs: ['userId'],
                    },
                    mediaByProjectSlug: {
                      ...offsetLimitPagination(),
                      keyArgs: ['organizationSlug', 'slug'],
                    },
                  },
                },
              },
            },
          }}
        >
          {children}
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
)

export default App
