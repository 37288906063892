import { UserIcon } from '@heroicons/react/24/solid'

const UserPlaceholderAvatar = (options) => {
  return (
    <div className={`w-full bg-primary-500 ${options?.padding}`}>
      <UserIcon className="w-full text-primary-300" />
    </div>
  )
}

export default UserPlaceholderAvatar
