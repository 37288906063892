import Skeleton from 'react-loading-skeleton'

const HeaderLoading = () => {
  return (
    <div className="flex items-start">
      <Skeleton width={72} height={72} circle={true} />
      <div className="ml-4 flex flex-grow items-center justify-between">
        <div>
          <Skeleton width={400} />
          <Skeleton width={300} />
          <Skeleton width={250} />
        </div>
        <div className="flex items-center space-x-4">
          <Skeleton height={28} width={75} />
          <Skeleton height={32} width={120} />
        </div>
      </div>
    </div>
  )
}

export default HeaderLoading
