import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  MagnifyingGlassIcon,
  ArrowUturnLeftIcon,
} from '@heroicons/react/20/solid'
import {
  ChatBubbleLeftRightIcon,
  PhotoIcon,
  UserIcon,
} from '@heroicons/react/24/solid'
import algoliasearch from 'algoliasearch/lite'
import { format, formatDistance } from 'date-fns'
import {
  Snippet,
  Hits,
  InstantSearch,
  SearchBox,
  useInstantSearch,
} from 'react-instantsearch-hooks-web'

import { Link, routes } from '@redwoodjs/router'

const DESC_TRUNCATE_LENGTH = 40

const linkRoute = (hit) => {
  if (hit.postProjectSlug) {
    const parts = {
      organizationSlug: hit.postOrganizationSlug,
      slug: hit.postProjectSlug,
      id: hit.postRootId,
    }

    // if this is a reply, scroll to it
    if (hit.postDepth !== 1) {
      parts.focus = `Post-${hit.id}`
    }

    return routes.projectPost(parts)
  }

  if (hit.postUsername) {
    return routes.user({
      username: hit.postUsername,
      tab: 'posts',
      id: hit.postRootId,
    })
  }
}

const NoSearchResults = ({ children }) => {
  const { results } = useInstantSearch()

  if (results.query.trim() === '') {
    return null
  }

  // TODO add a check for some state that keeps track of whether to show results or not. could keep the search string in the box but hide results until it focuses again
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        <div className="absolute left-0 right-0 rounded-b bg-white p-4 text-center text-sm text-secondary-400 shadow">
          No results found
        </div>
        <div hidden>{children}</div>
      </>
    )
  }

  return children
}

const Search = () => {
  const [listening, setListening] = useState(false)
  const [showResults, setShowResults] = useState(true)
  const resultsRef = useRef()

  useEffect(() => {
    if (listening) return
    if (!resultsRef.current) return

    setListening(true)

    const listener = (event) => {
      // don't close if clicking in the resultset itself
      if (resultsRef?.current?.contains(event.target)) {
        return
      }
      setShowResults(false)
    }

    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  const PostHit = useCallback(({ hit }) => {
    return (
      <Link
        to={linkRoute(hit)}
        onClick={() => setShowResults(false)}
        className="items-center space-x-2 rounded p-2 text-xs text-secondary-500 transition duration-100 hover:bg-primary-50 hover:text-primary-800 hover:text-secondary-600 md:flex"
      >
        <div className="flex w-48 flex-shrink-0 items-center">
          <img
            src={hit.postAvatar}
            alt={`${hit.postUsername}'s avatar`}
            className="w-8 rounded-full border-2 border-white shadow"
          />
          <div className="ml-1 font-semibold text-secondary-600">
            @{hit.postUsername}
          </div>
        </div>
        <div className="w-full">
          <div className="flex-1 items-center justify-between md:flex">
            <div className="-ml-1 flex items-start space-x-2 md:ml-0 md:items-center md:pl-2">
              {/* <span className="mr-1 text-2xl">
                { {hit.postCategorySymbol ? hit.postCategorySymbol : '🌎'} }
              </span> */}
              <span className="w-6 flex-shrink-0 text-secondary-400">
                <ChatBubbleLeftRightIcon />
              </span>
              {hit.postDepth > 1 && (
                <ArrowUturnLeftIcon className="mr-2 w-4 shrink-0 rotate-180 text-secondary-400" />
              )}
              <Snippet
                hit={hit}
                attribute="postBody"
                classNames={{
                  highlighted:
                    'rounded-sm px-1 bg-primary-100 text-primary-700',
                }}
              />
            </div>
            <div className="ml-4 whitespace-nowrap text-right text-xs text-secondary-400 md:text-left">
              {formatDistance(new Date(hit.postCreatedAt), new Date(), {
                addSuffix: true,
              })}
            </div>
          </div>
        </div>
      </Link>
    )
  }, [])

  const UserHit = useCallback(({ hit }) => {
    let shortBio = hit.userBio

    if (shortBio) {
      shortBio = shortBio.split(' ').slice(0, DESC_TRUNCATE_LENGTH)
      shortBio = shortBio.join(' ') + '...'

      if (shortBio.length > DESC_TRUNCATE_LENGTH) {
        shortBio += '...'
      }
    }

    return (
      <Link
        to={routes.user({ username: hit.userUsername })}
        onClick={() => setShowResults(false)}
        className="items-center space-x-2 rounded p-2 text-xs text-secondary-500 transition duration-100 hover:bg-primary-50 hover:text-primary-800 hover:text-secondary-600 md:flex"
      >
        <div className="flex w-48 flex-shrink-0 items-center">
          <img
            src={hit.userAvatar}
            alt={`${hit.userUsername}'s avatar`}
            className="w-8 rounded-full border-2 border-white shadow"
          />
          <div className="ml-1 font-semibold text-secondary-600">
            @{hit.userUsername}
          </div>
        </div>
        <div className="w-full">
          <div className="flex-1 items-center justify-between md:flex">
            <div className="-ml-1 flex items-start space-x-2 md:ml-0 md:items-center md:pl-2">
              <span className="w-6 flex-shrink-0 text-secondary-400">
                <UserIcon />
              </span>
              <span>{shortBio}</span>
            </div>
            <div className="ml-4 mt-1 whitespace-nowrap text-right text-xs text-secondary-400 md:mt-0 md:text-left">
              Member since {format(new Date(hit.userCreatedAt), 'MMM dd yyyy')}
            </div>
          </div>
        </div>
      </Link>
    )
  }, [])

  const ProjectHit = useCallback(({ hit }) => {
    let shortDescription = hit.projectDescription

    if (shortDescription) {
      shortDescription = shortDescription
        .split(' ')
        .slice(0, DESC_TRUNCATE_LENGTH)
      shortDescription = shortDescription.join(' ') + '...'

      if (shortDescription.length > DESC_TRUNCATE_LENGTH) {
        shortDescription += '...'
      }
    }

    return (
      <Link
        to={routes.project({
          organizationSlug: hit.projectOrgSlug,
          slug: hit.projectSlug,
        })}
        onClick={() => setShowResults(false)}
        className="items-center space-x-2 rounded p-2 text-xs text-secondary-500 transition duration-100 hover:bg-primary-50 hover:text-primary-800 hover:text-secondary-600 md:flex"
      >
        <div className="flex w-64 items-center">
          <img
            src={hit.projectAvatar}
            alt={hit.projectName}
            className="w-8 rounded-full border-2 border-white shadow"
          />
          <div className="ml-1 font-semibold text-secondary-600">
            {hit.projectOrgName} • {hit.projectName}
          </div>
        </div>
        <div className="w-full">
          <div className="flex-1 items-center justify-between md:flex">
            <div className="-ml-1 flex items-start space-x-2 md:ml-0 md:items-center md:pl-2">
              <span className="w-6 flex-shrink-0 text-secondary-400">
                <PhotoIcon />
              </span>
              <span>{shortDescription}</span>
            </div>
            {hit.projectLastPostAt && (
              <div className="ml-4 whitespace-nowrap text-right text-xs text-secondary-400 md:text-left">
                Last Post{' '}
                {format(new Date(hit.projectLastPostAt), 'MMM dd yyyy')}
              </div>
            )}
          </div>
        </div>
      </Link>
    )
  }, [])

  const HitComponent = useCallback(({ hit }) => {
    const { uiState, setUiState } = useInstantSearch()

    switch (hit.type) {
      case 'Post':
        return <PostHit hit={hit} uiState={uiState} setUiState={setUiState} />
      case 'User':
        return <UserHit hit={hit} uiState={uiState} setUiState={setUiState} />
      case 'Project':
        return (
          <ProjectHit hit={hit} uiState={uiState} setUiState={setUiState} />
        )
      default:
        return null
    }
  }, [])

  const algoliaClient = useMemo(() => {
    return algoliasearch(
      process.env.ALGOLIA_APP_ID,
      process.env.ALGOLIA_API_KEY
    )
  }, [])

  return (
    <div ref={resultsRef} className="mt-[2px] w-full md:relative">
      <InstantSearch
        searchClient={algoliaClient}
        indexName={process.env.ALGOLIA_INDEX_NAME}
      >
        <div className="flex items-center py-3">
          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
          <SearchBox
            placeholder="Search"
            classNames={{
              root: 'h-full flex-1 pr-4',
              form: 'flex h-full',
              input:
                'flex-1 text-sm block h-full w-full border-transparent py-2 pl-2 md:pl-4 pr-3 text-secondary-500 placeholder-secondary-300 focus:border-transparent focus:placeholder-secondary-400 focus:outline-none focus:ring-0',
              submit: 'hidden',
              reset: 'fill-current text-secondary-400',
            }}
            onFocus={() => setShowResults(true)}
          />
        </div>

        <div className={showResults ? '' : 'hidden'}>
          <NoSearchResults>
            <Hits
              classNames={{
                root: 'absolute left-0 right-0 w-full bg-white p-4 shadow-md rounded-b max-h-screen overflow-y-scroll',
                list: 'space-y-2',
              }}
              hitComponent={HitComponent}
            />
          </NoSearchResults>
        </div>
      </InstantSearch>
    </div>
  )
}

export default Search
