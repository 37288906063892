import { useAuth } from 'src/auth'
import SideNavigation from 'src/components/SideNavigation'
import WelcomeBanner from 'src/components/WelcomeBanner/WelcomeBanner'

import LoggedOutNavCell from './subs/LoggedOutNavCell'
import MainNavCell from './subs/MainNavCell'
import TopLevelNav from './subs/TopLevelNav'

const MyHomeLayout = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth()

  return (
    <>
      <SideNavigation>
        <nav className="">
          <TopLevelNav />
          {isAuthenticated ? (
            <MainNavCell userId={currentUser?.id} />
          ) : (
            <LoggedOutNavCell />
          )}
        </nav>
      </SideNavigation>
      <main className="min-h-screen bg-secondary-50 py-8 md:ml-64">
        <div className="mx-auto max-w-screen-lg">
          {!isAuthenticated && (
            <div className="mx-2 md:mx-4">
              <WelcomeBanner />
            </div>
          )}
          {children}
        </div>
      </main>
    </>
  )
}

export default MyHomeLayout
