import { Fragment, useContext } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { navigate, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { ApplicationContext } from 'src/layouts/ApplicationLayout'

const SideNavigation = ({ children }) => {
  const { sidebarOpen, setSidebarOpen, toggleNewProjectModal } =
    useContext(ApplicationContext)
  const { currentUser, isAuthenticated } = useAuth()

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className={`theme-${
            isAuthenticated ? currentUser.preferences?.theme : 'sky'
          } relative z-40 md:hidden`}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-secondary-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="mt-2 h-0 flex-1 overflow-y-auto">
                  <div className="flex items-center justify-end py-2 pr-4">
                    <button
                      type="button"
                      className="button button-success"
                      onClick={() => {
                        isAuthenticated
                          ? toggleNewProjectModal()
                          : navigate(routes.signup())
                        setSidebarOpen(false)
                      }}
                    >
                      + New Project
                    </button>
                  </div>
                  <nav className="">{children}</nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="fixed bottom-0 top-16 hidden w-64 overflow-auto bg-secondary-50 pr-1 md:block">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="h-full border-r border-secondary-200 bg-white pb-12">
          <div className="">{children}</div>
        </div>
      </div>
    </>
  )
}

export default SideNavigation
