import { Link, routes, useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import Header from 'src/components/Header'
import HeaderLoading from 'src/components/HeaderLoading'
import ProjectPlaceholderAvatar from 'src/components/ProjectPlaceholderAvatar'

export const QUERY = gql`
  query FindProjectForProjectHeaderCellQuery(
    $organizationSlug: String!
    $slug: String!
    $userId: Int
  ) {
    project: projectBySlug(
      organizationSlug: $organizationSlug
      slug: $slug
      userId: $userId
    ) {
      id
      name
      slug
      avatar
      description
      createdAt
      updatedAt
      followerCount
      isFollowing
      projectMembershipRoleName
      url
      onGitHub
      publishAsBlog
      organization {
        id
        name
        slug
      }
    }
  }
`

export const beforeQuery = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { organizationSlug, slug } = useParams()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentUser } = useAuth()

  return {
    variables: { organizationSlug, slug, userId: currentUser?.id },
    fetchPolicy: 'cache-and-network',
  }
}

export const Loading = () => {
  return <HeaderLoading />
}

export const Empty = () => <div></div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ project }) => {
  const TitleComponent = (
    <>
      <Link
        className="hover:underline"
        to={routes.userOrOrg({
          slug: project.organization.slug,
          tab: 'posts',
        })}
      >
        {project.organization.name}
      </Link>
      <span className="mx-1">•</span>
      <Link
        className="hover:underline"
        to={routes.projectPosts({
          organizationSlug: project.organization.slug,
          slug: project.slug,
        })}
      >
        {project.name}
      </Link>
    </>
  )

  return (
    <Header
      avatar={project.avatar || ProjectPlaceholderAvatar}
      avatarPadding="p-4"
      name={project.name}
      title={TitleComponent}
      since={project.createdAt}
      text={project.description}
      membership={project.projectMembershipRoleName}
      slug={project.slug}
      organizationSlug={project.organization.slug}
      follow={{
        isFollowing: project.isFollowing,
        count: project.followerCount,
        type: 'Project',
        id: project.id,
        refetch: {
          query: QUERY,
          variables: {
            organizationSlug: project.organization.slug,
            slug: project.slug,
          },
        },
      }}
      blog={project.publishAsBlog}
      url={project.url}
    />
  )
}
