import { fragmentRegistry } from 'src/App'

export const POST_FIELDS_FRAGMENT = gql`
  fragment PostFields on Post {
    id
    userId
    body
    createdAt
    updatedAt
    projectMembershipRoleName
    projectId
    parentId
    rootId
    replyCount
    depth
    deleted
    isViewed
    parent {
      id
      userId
    }
    root {
      id
      userId
    }
    user {
      id
      username
      avatar
    }
    project {
      id
      name
      slug
      publicWrite
      isMember
      organization {
        id
        name
        slug
      }
    }
    projectCategory {
      id
      name
      slug
      symbol
    }
    links {
      id
      url
      image
      title
      description
    }
  }
`

export const USER_PREVIEW_FIELDS_FRAGMENT = gql`
  fragment UserPreviewFields on User {
    id
    firstName
    lastName
    username
    avatar
    bio
    url
    createdAt
    isFollowing
    followerCount
    popularProjects {
      id
      name
      slug
      postsCount
      organization {
        id
        name
        slug
      }
    }
  }
`

export const PROJECT_PREVIEW_FIELDS_FRAGMENT = gql`
  fragment ProjectPreviewFields on Project {
    id
    name
    slug
    avatar
    description
    url
    createdAt
    isFollowing
    followerCount
    organization {
      id
      name
      slug
    }
  }
`
