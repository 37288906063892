import { useContext } from 'react'

import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'
import { CameraIcon, NewspaperIcon, CogIcon } from '@heroicons/react/24/solid'

import { Link, NavLink, routes, useMatch } from '@redwoodjs/router'
import { useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import SideNavLoading from 'src/components/SideNavLoading'
import { ApplicationContext } from 'src/layouts/ApplicationLayout'

export const QUERY = gql`
  query FindProjectForProjectCategoriesCellQuery(
    $organizationSlug: String!
    $slug: String!
  ) {
    project: projectBySlug(organizationSlug: $organizationSlug, slug: $slug) {
      id
      name
      slug
      avatar
      mediaCount
      organization {
        id
        name
        slug
      }
      projectCategories {
        id
        name
        slug
        symbol
        postsCount
      }
    }
  }
`

export const beforeQuery = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { organizationSlug, slug } = useParams()

  return {
    variables: { organizationSlug, slug },
    fetchPolicy: 'cache-and-network',
  }
}

const classNames = (...classes) => classes.filter(Boolean).join(' ')

export const Loading = () => {
  return <SideNavLoading />
}

export const Empty = () => <div></div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ project }) => {
  const { setSidebarOpen } = useContext(ApplicationContext)

  const { currentUser } = useAuth()
  const { organizationSlug, slug, category } = useParams()
  const mediaMatch = useMatch(
    routes.projectMedia({ organizationSlug, slug })
  ).match
  const settingsMatch = useMatch(
    routes.projectSettings({ organizationSlug, slug })
  ).match

  const onLinkClick = () => {
    window.plausible &&
      window.plausible('pageview', { u: window.prepareUrl(['category']) })
    setSidebarOpen(false)
  }

  const projectCategoriesClass =
    'flex transform items-center justify-between rounded-r-sm px-2 py-2 text-primary-600 duration-150 hover:bg-primary-50'

  const settingsBaseClass =
    'flex transform items-center justify-between rounded-r-sm px-2 py-3 text-primary-600 duration-150 hover:bg-primary-50'

  return (
    <>
      <header className="font-semibold text-primary-600">
        <Link
          to={currentUser ? routes.latest() : routes.discover()}
          className="group flex w-full items-center px-3 py-3 text-sm text-secondary-300 transition duration-100 hover:text-secondary-500"
          onClick={() => setSidebarOpen(false)}
        >
          <ArrowLeftCircleIcon className="w-5 text-secondary-300 transition duration-100 group-hover:text-secondary-500" />
          <span className="ml-2">Home</span>
        </Link>
      </header>

      <ul className="text-sm font-semibold text-primary-600">
        <li className="">
          <Link
            to={routes.projectPosts({
              organizationSlug: project.organization.slug,
              slug: project.slug,
            })}
            className={classNames(
              'flex transform items-center rounded-r-sm px-2 py-3 duration-150 hover:bg-primary-50',
              !category &&
                !mediaMatch &&
                !settingsMatch &&
                `-mr-1 !bg-primary-600 text-white hover:!bg-primary-600`
            )}
            onClick={() => setSidebarOpen(false)}
          >
            <span
              className={classNames(
                !category && !mediaMatch && !settingsMatch
                  ? 'text-primary-200'
                  : 'text-primary-400',
                `ml-1 w-5 text-center`
              )}
            >
              <NewspaperIcon className="w-full" />
            </span>
            <span className="ml-2">Summary</span>
          </Link>
        </li>

        {project.projectCategories.map((category) => {
          return (
            <li key={category.id} className="">
              <NavLink
                to={routes.projectPosts({
                  organizationSlug: project.organization.slug,
                  slug: project.slug,
                  category: category.slug,
                })}
                className={classNames(projectCategoriesClass)}
                activeClassName={`${projectCategoriesClass} group active !bg-primary-600 text-white hover:!bg-primary-600 -mr-1`}
                onClick={onLinkClick}
              >
                <div className="flex items-center">
                  <span className="inline-block h-7 w-7 rounded-full text-center text-xl">
                    {category.symbol}
                  </span>
                  <span className="ml-1">{category.name}</span>
                </div>
                <div>
                  <span
                    className={classNames(
                      'ml-3 rounded-full bg-primary-100 px-2 py-1 text-xs text-primary-400 group-[.active]:bg-primary-500 group-[.active]:text-primary-200 '
                    )}
                  >
                    {category.postsCount}
                  </span>
                </div>
              </NavLink>
            </li>
          )
        })}

        <li className="">
          <NavLink
            to={routes.projectMedia({
              organizationSlug: project.organization.slug,
              slug: project.slug,
            })}
            className={settingsBaseClass}
            activeClassName={`${settingsBaseClass} group active text-white !bg-primary-600 hover:!bg-primary-600 -mr-1`}
            onClick={() => setSidebarOpen(false)}
          >
            <div className="flex items-center">
              <span className="ml-1 block w-5 text-primary-400 group-[.active]:text-primary-200">
                <CameraIcon className="w-full" />
              </span>
              <span className="ml-2">Media</span>
            </div>
            <div>
              <span
                className={classNames(
                  'ml-3 rounded-full bg-primary-100 px-2 py-1 text-xs text-primary-500 group-[.active]:bg-primary-500 group-[.active]:text-primary-200 '
                )}
              >
                {project.mediaCount}
              </span>
            </div>
          </NavLink>
        </li>
        {currentUser?.permissions?.includes(
          `project-${project.id}-editProject`
        ) && (
          <li>
            <NavLink
              to={routes.projectSettings({
                organizationSlug: project.organization.slug,
                slug: project.slug,
              })}
              className={settingsBaseClass}
              activeClassName={`${settingsBaseClass} group active text-white !bg-primary-600 hover:!bg-primary-600 -mr-1`}
              onClick={() => setSidebarOpen(false)}
            >
              <div className="flex items-center">
                <span className="ml-1 block w-5 text-primary-400 group-[.active]:text-primary-200">
                  <CogIcon className="w-full" />
                </span>
                <span className="ml-2">Settings</span>
              </div>
            </NavLink>
          </li>
        )}
      </ul>
    </>
  )
}
