import { useEffect } from 'react'

import { BookmarkIcon } from '@heroicons/react/20/solid'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

export const CREATE_MUTATION = gql`
  mutation CreateFollowPinnedMutation($input: CreateFollowInput!) {
    createFollow(input: $input) {
      id
      userId
      followType
      followId
      pinned
    }
  }
`

export const UPDATE_MUTATION = gql`
  mutation ToggleFollowPinnedMutation($id: Int!, $input: UpdateFollowInput!) {
    updateFollow(id: $id, input: $input) {
      id
      pinned
    }
  }
`

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const PinButton = ({ item, className, refetch }) => {
  const [createFollow, { loading: createLoading, error: createError }] =
    useMutation(CREATE_MUTATION)
  const [updateFollow, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_MUTATION)

  useEffect(() => {
    if (createError || updateError) {
      console.error(createError || updateError)
      toast.error(createError?.message || updateError?.message)
    }
  }, [createError, updateError])

  const onPinned = (event, followId) => {
    if (followId) {
      updateFollow({
        variables: {
          id: followId,
          input: {
            pinned: !item.pinned,
          },
        },
        refetchQueries: [refetch].flat(),
      })
    } else {
      createFollow({
        variables: {
          input: {
            followId: item.id,
            followType: item.__typename,
            pinned: !item.pinned,
          },
        },
        refetchQueries: [refetch].flat(),
      })
    }

    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <button
      type="button"
      onClick={(event) => onPinned(event, item.followId)}
      title={item.pinned ? 'Remove favorite' : 'Mark as favorite'}
      className={classNames(
        item.pinned
          ? 'text-yellow-400 hover:text-yellow-300'
          : 'text-secondary-200 hover:text-secondary-300',
        'ml-2 inline-block rounded-full px-0 transition duration-150 md:hidden md:group-hover:block',
        className
      )}
    >
      <BookmarkIcon className="w-4" />
    </button>
  )
}

export default PinButton
