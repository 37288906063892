import { Link, routes } from '@redwoodjs/router'

const LogoutMenu = () => {
  return (
    <div className="flex items-start space-x-2 text-sm">
      <Link to={routes.login()} className="link font-semibold !underline">
        Login
      </Link>
      <span>or</span>
      <Link to={routes.signup()} className="link font-semibold !underline">
        Signup
      </Link>
    </div>
  )
}

export default LogoutMenu
