import { cloneElement, useContext, useMemo } from 'react'

import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'

import { Link, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import LeftNavGroup from 'src/components/LeftNavGroup'
import SideNavLoading from 'src/components/SideNavLoading'
import { ApplicationContext } from 'src/layouts/ApplicationLayout'

import PinButton from './subs/PinButton'

export const QUERY = gql`
  query FindForMainNavCellQuery {
    myProjects: projectsByUser {
      id
      name
      slug
      pinned
      followId
      organization {
        id
        name
        slug
      }
    }
    projects: followedProjects {
      id
      name
      slug
      pinned
      followId
      organization {
        id
        name
        slug
      }
    }
    users: followedUsers {
      id
      username
      pinned
      followId
    }
  }
`

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const LinkComp = ({ to, label, item, className, icon }) => {
  const { setSidebarOpen } = useContext(ApplicationContext)
  const { currentUser } = useAuth()

  return (
    <Link
      to={to}
      className={classNames(
        'w-full pr-6 text-primary-600 transition-colors duration-100 hover:bg-primary-50 hover:text-primary-900',
        'group flex items-center rounded-md py-1 text-base font-semibold',
        className
      )}
      onClick={() => setSidebarOpen(false)}
    >
      {icon && cloneElement(icon, { className: 'w-5 mr-2 text-primary-400' })}
      <span className="flex-1 text-sm font-semibold">{label}</span>
      {currentUser && (
        <PinButton
          item={item}
          className={className + ' -mr-6'}
          refetch={{ query: QUERY, variables: { userId: currentUser?.id } }}
        />
      )}
    </Link>
  )
}

export const UserLink = ({ item, className }) => {
  return (
    <LinkComp
      to={routes.user({ username: item.username, tab: 'posts' })}
      label={`@${item.username}`}
      item={item}
      className={className}
      icon={<UserCircleIcon />}
    />
  )
}

export const ProjectLink = ({ item, className }) => {
  const { currentUser } = useAuth()

  return (
    <LinkComp
      to={routes.projectPosts({
        organizationSlug: item.organization.slug,
        slug: item.slug,
      })}
      label={
        item.organization.slug === currentUser?.username
          ? item.name
          : `${item.organization.slug}/${item.slug}`
      }
      item={item}
      className={className}
      icon={<PhotoIcon />}
    />
  )
}

const NoFollows = () => (
  <nav className="flex flex-1 px-4 py-4">
    <div className="flex flex-1 items-center">
      <div className="text-center text-sm text-primary-500">
        You aren't following any projects yet!{' '}
        <Link to={routes.home()} className="link">
          Go find some!
        </Link>
      </div>
    </div>
  </nav>
)

export const Loading = () => {
  return <SideNavLoading />
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ myProjects, projects, users }) => {
  const { currentUser } = useAuth()

  const favorites = useMemo(() => {
    const pinned = []

    projects.forEach((project) => {
      if (project.pinned) {
        pinned.push({
          label:
            project.organization.slug === currentUser.username
              ? project.name
              : `${project.organization.slug}/${project.slug}`,
          link: routes.projectPosts({
            organizationSlug: project.organization.slug,
            slug: project.slug,
          }),
          type: 'project',
          data: project,
          icon: <PhotoIcon />,
        })
      }
    })

    users.forEach((user) => {
      if (user.pinned) {
        pinned.push({
          label: `@${user.username}`,
          link: routes.user({ username: user.username, tab: 'posts' }),
          type: 'user',
          data: user,
          icon: <UserCircleIcon />,
        })
      }
    })

    pinned.sort((a, b) => {
      if (a.label > b.label) return 1
      if (a.label < b.label) return -1
      return 0
    })

    return pinned
  }, [projects, users])

  if (!projects.length && !users.length) {
    return <NoFollows />
  }

  return (
    <>
      {!!favorites?.length && (
        <>
          <ul className="mx-1 mb-4 mt-2 space-y-2 ">
            {favorites.map((item) => (
              <li key={`pinned-${item.type}-${item.data.id}`} className="block">
                <LinkComp
                  to={item.link}
                  label={item.label}
                  item={item.data}
                  className="px-2"
                  icon={item.icon}
                />
              </li>
            ))}
          </ul>
        </>
      )}

      <div className="space-y-2">
        <LeftNavGroup
          label="My Projects"
          items={myProjects}
          LinkComponent={ProjectLink}
          ready={!!favorites}
          isOpen={!favorites?.length}
        />

        <LeftNavGroup
          label="Following Projects"
          items={projects}
          LinkComponent={ProjectLink}
          ready={!!favorites}
          isOpen={!favorites?.length}
        />

        <LeftNavGroup
          label="Following Users"
          items={users}
          LinkComponent={UserLink}
          ready={!!favorites}
          isOpen={!favorites?.length}
        />
      </div>
    </>
  )
}
