import LeftNavGroup from 'src/components/LeftNavGroup'
import SideNavLoading from 'src/components/SideNavLoading'

import { ProjectLink, UserLink } from '../MainNavCell'

export const QUERY = gql`
  query FindForLoggedOutNavCellQuery {
    projects: popularProjects {
      id
      name
      slug
      organization {
        id
        name
        slug
      }
    }

    users: popularUsers {
      id
      username
    }
  }
`

export const Loading = () => {
  return <SideNavLoading />
}

export const Success = ({ projects, users }) => {
  return (
    <>
      <LeftNavGroup
        label="Popular Projects"
        items={projects}
        LinkComponent={ProjectLink}
        ready={true}
        isOpen={true}
        sort={false}
      />

      <LeftNavGroup
        label="Popular Users"
        items={users}
        LinkComponent={UserLink}
        ready={true}
        isOpen={true}
        sort={false}
      />
    </>
  )
}
