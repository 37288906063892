import { useEffect, useRef, useState } from 'react'

import { PencilIcon, PhotoIcon } from '@heroicons/react/24/solid'
import * as filestack from 'filestack-js'
import { paramCase } from 'param-case'
import { FileUploader } from 'react-drag-drop-files'

import {
  FieldError,
  Form,
  Label,
  TextField,
  Submit,
  useForm,
  TextAreaField,
} from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner'

const FILE_TYPES = ['jpeg', 'jpg', 'png', 'gif']

const classNames = (...classes) => classes.filter(Boolean).join(' ')

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProjectMutation($input: CreateProjectInput!) {
    project: createProject(input: $input) {
      id
      name
      slug
      description
      avatar
      url
      organization {
        id
        name
        slug
        description
        avatar
        url
      }
    }
  }
`

const NewProjectModal = ({ onCancel, onCreate }) => {
  const { currentUser } = useAuth()
  const filestackClient = filestack.init(process.env.FILESTACK_API_KEY)
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [syncSlug, setSyncSlug] = useState(true)
  const nameRef = useRef()
  const slugRef = useRef()
  const formMethods = useForm()

  const [createProject, { loading, error }] = useMutation(
    CREATE_PROJECT_MUTATION,
    {
      onCompleted: ({ project }) => {
        onCreate(project)
      },
    }
  )

  // any error in creating a project
  useEffect(() => {
    if (error) {
      toast.error(error.message)
    }
  }, [error])

  const onSubmit = (data) => {
    createProject({
      variables: { input: data },
    })
  }

  const uploadFile = async (file) => {
    setUploading(true)
    const { url } = await filestackClient.upload(file)
    setUploading(false)
    setAvatarUrl(url)
  }

  const onFileSelect = (file) => {
    uploadFile(file)
  }

  const onFileTypeError = (error) => {
    toast.error(`Supported image formats are ${FILE_TYPES.join(', ')}`)
  }

  const onFileSizeError = (error) => {
    toast.error(`Images must be less than 100MB`)
  }

  const onNameChange = () => {
    if (syncSlug) {
      formMethods.setValue('slug', paramCase(nameRef.current.value))
    }
  }

  // if user manually enters a slug, stop syncing with name
  const disableSyncSlug = () => {
    setSyncSlug(false)
  }

  return (
    <div className="fixed inset-0 z-10 flex flex-col items-center justify-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="h-full w-full bg-black opacity-25"
        onClick={onCancel}
      ></div>
      <Form
        formMethods={formMethods}
        onSubmit={onSubmit}
        className="form absolute bottom-0 top-0 flex max-w-screen-sm flex-col overflow-y-scroll rounded bg-white shadow-md md:bottom-8 md:top-24"
      >
        <div className="flex-grow">
          <header className="rounded-t bg-secondary-100 p-8">
            <h2 className="text-center text-xl font-semibold text-secondary-600">
              Create a Project
            </h2>
          </header>

          <div className="mx-12 mt-12 space-y-6">
            <div className="text-center">
              <FileUploader
                name="file"
                classes="w-auto !inline-block cursor-pointer rounded transition duration-150"
                types={FILE_TYPES}
                label="Drag and drop, select or paste to add images"
                onTypeError={onFileTypeError}
                onSizeError={onFileSizeError}
                handleChange={onFileSelect}
                hoverTitle=" "
                maxSize={100}
                // eslint-disable-next-line react/no-children-prop
                children={
                  <div
                    className="group flex flex-col items-center justify-center"
                    title="Upload a project avatar"
                  >
                    <div className="relative flex h-32 w-32 items-center justify-center overflow-auto rounded-full border-4 border-white bg-secondary-100 text-secondary-200 shadow transition duration-150 group-hover:bg-secondary-200 group-hover:text-secondary-300">
                      {avatarUrl ? (
                        <img
                          src={avatarUrl}
                          alt="Project avatar"
                          className="object-contain align-middle"
                        />
                      ) : (
                        <PhotoIcon className="absolute left-1/2 top-1/2 w-16 -translate-x-1/2 -translate-y-1/2" />
                      )}
                      {uploading && (
                        <div className="absolute inset-0 z-10 h-full w-full items-center justify-center ">
                          <div className="h-full w-full bg-white opacity-50"></div>
                          <LoadingSpinner
                            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                            size={8}
                          />
                        </div>
                      )}
                    </div>
                    {!uploading && (
                      <span className="absolute bottom-0 right-0 mt-2 text-center text-xs font-normal text-secondary-400">
                        <PencilIcon className="w-8 rounded-full bg-white p-2 shadow" />
                      </span>
                    )}
                  </div>
                }
              />
            </div>

            <div>
              <Label name="name">Name</Label>
              <TextField ref={nameRef} name="name" onChange={onNameChange} />
              <FieldError
                name="name"
                className="block text-xs text-danger-600"
              />
            </div>

            <div>
              <Label name="slug">Slug</Label>
              <div className="flex items-center">
                <span className="text-sm text-secondary-400">
                  https://spoke.run/{currentUser.username}/
                </span>
                <TextField
                  name="slug"
                  ref={slugRef}
                  className={classNames(`!mt-0 ml-1 px-2`)}
                  onKeyDown={disableSyncSlug}
                  validation={{
                    required: true,
                    pattern: {
                      value: /^[\w-]+$/,
                      message:
                        'Can only contain letters, numbers, underscores and dashes',
                    },
                  }}
                />
              </div>
              <FieldError
                name="slug"
                className="block text-xs text-danger-600"
              />
            </div>

            <div>
              <Label name="description">Description</Label>
              <TextAreaField name="description" className="h-24" />
              <small className="text-xs text-secondary-400">
                Optional, but gives a quick overview of your project
              </small>
              <FieldError
                name="description"
                className="block text-xs text-danger-600"
              />
            </div>

            <div>
              <Label name="url">URL</Label>
              <TextField name="url" />
              <small className="text-xs text-secondary-400">
                Optional, link to another website about this project
              </small>
              <FieldError
                name="url"
                className="block text-xs text-danger-600"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center space-x-4 rounded-b bg-secondary-100 p-8 ">
          <Submit disabled={loading} className="button button-success">
            Create
          </Submit>
          <button type="button" className="button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </Form>
    </div>
  )
}

export default NewProjectModal
