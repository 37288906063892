import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'

const CREATE_FOLLOW_MUTATION = gql`
  mutation CreateFollowMutation($input: CreateFollowInput!) {
    createFollow(input: $input) {
      id
    }
  }
`

const DELETE_FOLLOW_MUTATION = gql`
  mutation DeleteFollowMutation($followType: String!, $followId: Int!) {
    deleteFollow(followType: $followType, followId: $followId) {
      id
    }
  }
`

const FollowProjectButton = ({ isFollowing, type, id, refetch, as }) => {
  const { currentUser } = useAuth()
  const refetchQueries = [refetch].flat()
  const showAs = as || 'button'

  const [createFollow, { loading: _createLoading, error: _createError }] =
    useMutation(CREATE_FOLLOW_MUTATION, {
      onError: (error) => console.error(error),
    })
  const [deleteFollow, { loading: _deleteLoading, error: _deleteError }] =
    useMutation(DELETE_FOLLOW_MUTATION, {
      onError: (error) => console.error(error),
    })

  const onFollow = () => {
    createFollow({
      variables: {
        input: {
          followType: type,
          followId: id,
        },
      },
      refetchQueries,
    })
  }

  const onUnfollow = () => {
    deleteFollow({
      variables: {
        followType: type,
        followId: id,
      },
      refetchQueries,
    })
  }

  if (type === 'User' && id === currentUser.id) {
    return null
  }

  return isFollowing ? (
    <button
      type="button"
      className={showAs === 'button' ? 'button' : ''}
      onClick={onUnfollow}
    >
      Following
    </button>
  ) : (
    <button
      type="button"
      className={showAs === 'button' ? 'button button-success' : ''}
      onClick={onFollow}
    >
      Follow
    </button>
  )
}

export default FollowProjectButton
