import { Link, routes } from '@redwoodjs/router'

const WelcomeBanner = () => (
  <div className="mb-8 rounded border-2 border-primary-400 bg-primary-100 p-6">
    <header className="relative flex text-primary-900">
      <div>
        <h2 className="font-semibold text-primary-600">What is Spoke?</h2>
        <div className="items-start md:flex">
          <div>
            <p className="mt-2 text-sm">
              Spoke is a different kind of social network: one designed around
              <strong className="ml-1">creating things</strong>. You'll find
              people building software, hardware, art, music, and more. We want
              Spoke to be a place where you can share what you're working on,
              get feedback, and find inspiration.
            </p>
            <p className="mt-2 text-sm">
              What are you working on?{' '}
              <Link to={routes.signup()} className="font-semibold underline">
                Sign up
              </Link>{' '}
              and start sharing the process with others!
            </p>
          </div>
        </div>
      </div>{' '}
      <img
        alt="Spoke logo"
        src="/images/mark.png"
        className="ml-4 hidden h-32 w-32 md:block"
      />
    </header>
  </div>
)

export default WelcomeBanner
