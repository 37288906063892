import { useMemo } from 'react'

import { Disclosure, Transition } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const LeftNavGroup = ({
  label,
  items,
  LinkComponent,
  ready,
  isOpen,
  sort = true,
}) => {
  const sortedItems = useMemo(() => {
    if (sort) {
      return [...items].sort((a, b) => {
        let labelA, labelB

        if (a.__typename === 'User') {
          labelA = a.username.toLowerCase()
          labelB = b.username.toLowerCase()
        } else if (a.__typename === 'Project') {
          labelA = `${a.organization.slug}/${a.slug}`
          labelB = `${b.organization.slug}/${b.slug}`
        }

        if (labelA < labelB) {
          return -1
        }
        if (labelA > labelB) {
          return 1
        }
        return 0
      })
    } else {
      return items
    }
  }, [items, sort])

  if (!ready) {
    return null
  }

  return (
    <>
      <Disclosure as="div" key={label} className="" defaultOpen={isOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                'transform bg-white text-secondary-600 duration-150 hover:bg-secondary-50 hover:text-secondary-900',
                'group flex w-full items-center pr-2 text-left text-sm font-medium focus:outline-none'
              )}
            >
              <span className="flex-1 py-2 pl-3 text-xs uppercase text-secondary-400">
                {label}
              </span>
              <ChevronRightIcon
                className={classNames(
                  open ? 'rotate-90 text-secondary-400' : 'text-secondary-300',
                  'h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-secondary-400'
                )}
                aria-hidden="true"
              />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel as="ul" className="mx-1 mb-4 mt-2 space-y-2">
                {sortedItems.map((item) => (
                  <Disclosure.Button
                    key={`${label}-${item.id}`}
                    as="li"
                    className="group flex w-full items-center rounded-md px-2 text-sm font-medium text-secondary-600 hover:bg-secondary-50 hover:text-secondary-900"
                  >
                    <LinkComponent item={item} />
                  </Disclosure.Button>
                ))}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  )
}

export default LeftNavGroup
