import { useContext } from 'react'

import { useParams } from '@redwoodjs/router'

import { ApplicationContext } from 'src/layouts/ApplicationLayout'

const ProjectNotFoundPage = () => {
  const { toggleNewProjectModal } = useContext(ApplicationContext)
  const { organizationSlug } = useParams()

  return (
    <main className="flex min-h-screen items-center justify-center bg-secondary-50">
      <div className="w-full max-w-screen-sm rounded border bg-white p-8 text-center">
        <h2 className="mb-2 text-lg font-semibold text-primary-600">
          Project not found
        </h2>

        <div className="text-sm text-secondary-500">
          <span>Remember, you can always</span>
          <button
            type="button"
            className="link ml-1 underline"
            onClick={toggleNewProjectModal}
          >
            create
          </button>
          <span className="ml-1">as many personal projects as you want!</span>
        </div>
      </div>
    </main>
  )
}

export default ProjectNotFoundPage
