import { PhotoIcon } from '@heroicons/react/24/solid'

const ProjectPlaceholderAvatar = (options) => {
  return (
    <div className={`w-full bg-primary-500 ${options?.padding}`}>
      <PhotoIcon className="w-full text-primary-300" />
    </div>
  )
}

export default ProjectPlaceholderAvatar
