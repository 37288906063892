import { SkeletonTheme } from 'react-loading-skeleton'

import { PrivateSet, Set, Router, Route } from '@redwoodjs/router'

import { NewPostsContextProvider } from 'src/contexts/NewPostsContext'
import ApplicationLayout from 'src/layouts/ApplicationLayout'
import MyHomeLayout from 'src/layouts/MyHomeLayout'
import ProjectLayout from 'src/layouts/ProjectLayout'
import SessionLayout from 'src/layouts/SessionLayout'
import NotFoundPage from 'src/pages/Error/NotFoundPage'

import { useAuth } from './auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/settings-redirect" page={SettingsRedirectPage} name="settingsRedirect" />
      {/* eslint-disable-next-line @redwoodjs/unsupported-route-components */}
      <Set wrap={SkeletonTheme} baseColor="#e2e8f0" highlightColor="#f1f5f9">
        <Route path="/r/{code}" page={ContactRedirectPage} name="contactRedirect" />
        <Route path="/oauth-redirect" page={OauthRedirectPage} name="oauthRedirect" />

        {/* Helper to send user to their own settings page without knowing their username */}
        <Route path="/settings" page={SettingsRedirectPage} name="settings" />

        <Set wrap={SessionLayout}>
          <Route path="/login" page={SessionLoginPage} name="login" />
          <Route path="/signup" page={SessionSignupPage} name="signup" />
          <Route path="/forgot-password" page={SessionForgotPasswordPage} name="forgotPassword" />
          <Route path="/reset-password" page={SessionResetPasswordPage} name="resetPassword" />
        </Set>

        <Set wrap={[NewPostsContextProvider, ApplicationLayout]}>
          <Route path="/contact/{username}" page={ContactPage} name="contact" />

          <Set wrap={MyHomeLayout}>
            <Route path="/discover" page={DiscoverPage} name="discover" />
            <Route path="/tags/{tag}" page={TagsPage} name="tag" />
            <Route path="/tags" page={TagsPage} name="tags" />
            <PrivateSet unauthenticated="login">
              <Route path="/latest" page={LatestPage} name="latest" />
              <Route path="/engage" page={EngagePage} name="engage" />
            </PrivateSet>
          </Set>

          <Set wrap={ProjectLayout}>
            <Route path="/{organizationSlug}/{slug}" page={OrganizationProjectPage} name="project" />
            <Route path="/{organizationSlug}/{slug}/posts/{id:Int}" page={OrganizationProjectPostPage} name="projectPost" />
            <Route path="/{organizationSlug}/{slug}/posts" page={OrganizationProjectPostsPage} name="projectPosts" />
            <Route path="/{organizationSlug}/{slug}/media" page={OrganizationProjectMediaPage} name="projectMedia" />
            <PrivateSet unauthenticated="login">
              <Route path="/{organizationSlug}/{slug}/settings" page={OrganizationProjectSettingsPage} name="projectSettings" />
            </PrivateSet>
          </Set>

          {/* Users and Orgs both have top-level namespaces, so go to a page
              that determines which we're trying to view and then render the
              real one. Or, if you already know where you plan to go, just use
              that route directly */}

          <Route path="/{slug}" page={UserOrOrganizationPage} name="userOrOrg" />
          <Route path="/{username}" page={UserUserPage} name="user" />
        </Set>

        <Route path="/" page={HomePage} name="home" />
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
