import { createContext, useContext, useReducer } from 'react'

const reducer = (state, newState) => {
  return { ...state, ...newState }
}

export const NewPostsContext = createContext()

export const NewPostsContextProvider = ({ children }) => {
  const [newPostsCount, setNewPostsCount] = useReducer(reducer, {
    latest: 0,
    engage: 0,
    discover: 0,
  })

  return (
    <NewPostsContext.Provider value={{ newPostsCount, setNewPostsCount }}>
      {children}
    </NewPostsContext.Provider>
  )
}

export const useNewPostsContext = () => {
  const context = useContext(NewPostsContext)

  if (!context) {
    throw 'useNewPostsContext must be used within a NewPostsContextProvider'
  }

  return context
}
