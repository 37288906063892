import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'

import { Link, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import UserPlaceholderAvatar from 'src/components/UserPlaceholderAvatar'

export const QUERY = gql`
  query GetDraftsCountForLoginMenuCellQuery($id: Int!) {
    user(id: $id) {
      id
      draftsCount
    }
  }
`

export const beforeQuery = () => {
  const { currentUser } = useAuth()

  return { variables: { id: currentUser.id }, fetchPolicy: 'cache-first' }
}

export const Success = ({ user, toggleNewProjectModal }) => {
  const { currentUser, logOut } = useAuth()

  return (
    <>
      <button
        type="button"
        className="button button-success hidden !px-4 md:block"
        onClick={toggleNewProjectModal}
      >
        + New Project
      </button>

      {/* Profile dropdown */}
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex max-w-xs items-center overflow-hidden rounded bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            <span className="sr-only">Open user menu</span>
            {currentUser.avatar ? (
              <img
                className="h-10 w-10"
                src={currentUser.avatar}
                alt={`${currentUser.username} avatar`}
              />
            ) : (
              <div className="w-8 flex-shrink-0 overflow-hidden rounded-sm bg-secondary-100">
                <UserPlaceholderAvatar padding="p-1" />
              </div>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 font-semibold text-primary-600 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item key="profile">
              {({ close }) => (
                <Link
                  to={routes.user({
                    username: currentUser.username,
                  })}
                  className="block px-4 py-2 text-sm hover:bg-secondary-100"
                  onClick={close}
                >
                  Profile
                </Link>
              )}
            </Menu.Item>
            <Menu.Item key="settings">
              {({ close }) => (
                <Link
                  to={routes.user({
                    username: currentUser.username,
                    tab: 'settings',
                  })}
                  className="block px-4 py-2 text-sm hover:bg-secondary-100"
                  onClick={close}
                >
                  Settings
                </Link>
              )}
            </Menu.Item>
            <Menu.Item key="drafts">
              {({ close }) => (
                <Link
                  to={routes.user({
                    username: currentUser.username,
                    tab: 'drafts',
                  })}
                  className="flex items-center px-4 py-2 text-sm hover:bg-secondary-100"
                  onClick={close}
                >
                  <span>Drafts</span>
                  <span className="ml-3 rounded-full bg-primary-100 px-2 py-1 text-xs text-primary-400 group-[.active]:bg-primary-500 group-[.active]:text-primary-200 ">
                    {user.draftsCount}
                  </span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item key="contact">
              {({ close }) => (
                <Link
                  to={routes.contact({
                    username: currentUser.username,
                  })}
                  className="block px-4 py-2 text-sm hover:bg-secondary-100"
                  onClick={close}
                >
                  Contact Card
                </Link>
              )}
            </Menu.Item>
            <Menu.Item key="logout">
              <button
                type="button"
                onClick={logOut}
                className="block w-full px-4 py-2 text-left text-sm hover:bg-secondary-100"
              >
                Logout
              </button>
            </Menu.Item>
            {currentUser.permissions.includes('accessAdmin') && (
              <Menu.Item key="admin">
                {({ close }) => (
                  <Link
                    to={routes.home()}
                    className="block w-full border-t px-4 py-2 text-left text-sm hover:bg-danger-100 hover:text-danger-500"
                    onClick={close}
                  >
                    Admin
                  </Link>
                )}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
