import { useAuth } from 'src/auth'
import FollowProjectButton from 'src/components/Project/FollowProjectButton'

const FollowAction = ({ isFollowing, count, type, id, refetch }) => {
  const buttonProps = { isFollowing, type, id, refetch }
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return null
  }

  return (
    <ul className="flex items-center space-x-4">
      <li className="whitespace-nowrap text-xs font-semibold text-secondary-500 md:text-sm">
        {count} Follow{count !== 1 && 's'}
      </li>
      <li>
        <FollowProjectButton {...buttonProps} />
      </li>
    </ul>
  )
}

export default FollowAction
