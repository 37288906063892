// Whenever the list of times that someone visited one of the main homepage
// nav items changes, ask the server if there are any new posts to those
// sections since the last check

import { useEffect } from 'react'

import { useQuery } from '@redwoodjs/web'

import { useNewPostsContext } from 'src/contexts/NewPostsContext'

const QUERY = gql`
  query FindNewPostsCountForUser(
    $latestLastVisitAt: DateTime
    $engageLastVisitAt: DateTime
    $discoverLastVisitAt: DateTime
  ) {
    counts: newPostsCountForUser(
      latestLastVisitAt: $latestLastVisitAt
      engageLastVisitAt: $engageLastVisitAt
      discoverLastVisitAt: $discoverLastVisitAt
    ) {
      latestCount
      engageCount
      discoverCount
    }
  }
`

const faviconEl = document.querySelector('link[rel="icon"]')

export const useNewPostsCheck = () => {
  const { newPostsCount, setNewPostsCount } = useNewPostsContext()
  const { data, error } = useQuery(QUERY, {
    pollInterval: 30000,
  })

  useEffect(() => {
    if (data) {
      setNewPostsCount({
        latest: data.counts.latestCount,
        engage: data.counts.engageCount,
        discover: data.counts.discoverCount,
      })
    }
  }, [data, setNewPostsCount])

  useEffect(() => {
    if (
      newPostsCount.latest > 0 ||
      newPostsCount.engage > 0 ||
      newPostsCount.discover > 0
    ) {
      faviconEl.href = '/images/favicon-unread.png'
    } else {
      faviconEl.href = '/images/favicon.png'
    }
  }, [newPostsCount])

  useEffect(() => {
    if (error) {
      console.error('Error pulling Latest/Engage/Discover unread counts', error)
    }
  }, [error])
}
