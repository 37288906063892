import { Fragment, useState } from 'react'

import { Menu, Transition } from '@headlessui/react'
import {
  ClipboardDocumentCheckIcon,
  LinkIcon,
  EnvelopeIcon,
  EllipsisHorizontalIcon,
  ArrowUpOnSquareIcon,
  StarIcon,
} from '@heroicons/react/24/outline'
import {
  NewspaperIcon,
  StarIcon as StarFilledIcon,
} from '@heroicons/react/24/solid'

import { Link } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import FollowAction from 'src/components/FollowAction'
import ProjectRoleNameTag from 'src/components/ProjectRoleNameTag'

import FollowProjectButton from '../Project/FollowProjectButton/FollowProjectButton'

const Header = ({
  avatar,
  avatarPadding,
  title,
  link,
  name,
  nameSuffix,
  text,
  follow,
  membership,
  slug,
  organizationSlug,
  email,
  blog,
  url,
}) => {
  const { currentUser, isAuthenticated } = useAuth()
  const [showFullText, setShowFullText] = useState(false)
  const shareData = {
    title: `${name} on Spoke`,
    text,
    url: location.href,
  }

  // annoyingly complicated, but works to have different truncation lengths for
  // different screen widths
  let mdShortText = text
  let smShortText = text
  let mdShowMore = false
  let smShowMore = false

  if (text?.length > 200) {
    mdShortText = text.substring(0, 200) + '...'
    mdShowMore = true
  }
  if (text?.length > 80) {
    smShortText = text.substring(0, 80) + '...'
    smShowMore = true
  }

  let AvatarComp

  // can use a string (URL) for an avatar, or an actual avatar component
  if (typeof avatar === 'string') {
    AvatarComp = (
      <div className="w-full overflow-auto rounded">
        <img
          src={avatar}
          alt={`${name}'s avatar`}
          className="w-full object-contain"
        />
      </div>
    )
  } else {
    AvatarComp = avatar({ padding: avatarPadding })
  }

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(`${location.href}`)
    toast.success('Link copied to clipboard')
  }

  const onShare = async () => {
    await navigator.share(shareData)
  }

  const canFollow =
    follow &&
    isAuthenticated &&
    (follow.type !== 'User' || follow.id !== currentUser?.id)

  return (
    <div className="relative flex flex-col items-start justify-between md:flex-row md:items-center">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          <div className="w-10 overflow-hidden rounded md:mt-1 md:w-20">
            {AvatarComp}
          </div>
        </div>

        <div className="ml-4 flex-grow">
          <div className="flex flex-wrap items-center">
            <h2 className="title mr-6">
              {title || (
                <div className="flex items-center">
                  <Link className="hover:underline" to={link}>
                    {name}
                  </Link>
                  {nameSuffix}
                </div>
              )}
            </h2>
            {membership && (
              <div className="-mt-1 hidden md:block">
                <ProjectRoleNameTag label={membership} />
              </div>
            )}
          </div>

          <div className="subtitle mr-6 mt-1">
            {text && (
              <>
                {/* this arcane combination of classes makes it so that different screen sizes have different truncated text lengths */}
                <span className={showFullText ? 'hidden' : 'mb-2 md:hidden'}>
                  {smShortText}
                </span>
                <span
                  className={showFullText ? 'hidden' : 'mb-2 hidden md:inline'}
                >
                  {mdShortText}
                </span>
                <span className={!showFullText ? 'hidden' : 'mb-2'}>
                  {text}
                </span>
                {smShowMore && (
                  <button
                    type="button"
                    className="right ml-1 inline-block text-xs text-secondary-500 hover:underline md:hidden"
                    onClick={() => setShowFullText(!showFullText)}
                  >
                    Show {showFullText ? 'Less' : 'More'}
                  </button>
                )}
                {mdShowMore && (
                  <button
                    type="button"
                    className="right ml-1 inline-block hidden text-xs text-secondary-500 hover:underline md:inline-block"
                    onClick={() => setShowFullText(!showFullText)}
                  >
                    Show {showFullText ? 'Less' : 'More'}
                  </button>
                )}
              </>
            )}
          </div>
          <ul className="items-center text-xs md:flex md:space-x-2">
            {blog && (
              <li className="mt-1 flex items-center text-secondary-400">
                <NewspaperIcon className="w-3" title="Blog" />
                <a
                  href={`http://${slug}.${organizationSlug}.spoke.blog`}
                  className="ml-1 text-xs text-secondary-400 hover:text-secondary-500 hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  http://{slug}.{organizationSlug}.spoke.blog
                </a>
              </li>
            )}
            {url && (
              <li className="mt-1 flex items-center text-secondary-400">
                <LinkIcon className="w-3" title="URL" />
                <a
                  href={url}
                  className="ml-1 text-xs text-secondary-400 hover:text-secondary-500 hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {url}
                </a>
              </li>
            )}
            {email && (
              <li className="mt-1 flex items-center text-secondary-400">
                <EnvelopeIcon className="w-3" title="Email" />
                <a
                  href={`mailto:${email}`}
                  className="ml-1 text-xs text-secondary-400 hover:text-secondary-500 hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {email}
                </a>
              </li>
            )}
          </ul>
        </div>

        {/* flyout mobile menu */}
        <Menu as="div" className="absolute right-0 top-2 z-10 md:hidden">
          <div className="flex items-center">
            <Menu.Button className="py-half inline-flex w-full items-center justify-center rounded px-1 hover:bg-secondary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <EllipsisHorizontalIcon className="w-5 text-secondary-400" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-8 top-2 z-10 -mt-1 w-32 origin-top-right divide-y divide-gray-100 rounded bg-white shadow ring-1 ring-black ring-opacity-5 focus:outline-none md:top-3">
              <div className="">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active
                          ? 'bg-secondary-100 text-secondary-600'
                          : 'text-secondary-400'
                      } group flex w-full transform items-center rounded-t px-2 py-2 text-xs duration-100`}
                      onClick={onCopyLink}
                    >
                      <ClipboardDocumentCheckIcon
                        className="mr-2 w-4"
                        aria-hidden="true"
                      />
                      Copy Link
                    </button>
                  )}
                </Menu.Item>

                {navigator.canShare && navigator.canShare(shareData) && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? 'bg-secondary-100 text-secondary-600'
                            : 'text-secondary-400'
                        } group flex w-full transform items-center rounded-t px-2 py-2 text-xs duration-100`}
                        onClick={onShare}
                      >
                        <ArrowUpOnSquareIcon
                          className="mr-2 w-4"
                          aria-hidden="true"
                        />
                        Share
                      </button>
                    )}
                  </Menu.Item>
                )}

                {canFollow && (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={`${
                          active
                            ? 'bg-secondary-100 text-secondary-600'
                            : 'text-secondary-400'
                        } group flex w-full transform items-center rounded-t px-2 py-2 text-xs duration-100`}
                      >
                        {follow.isFollowing ? (
                          <StarFilledIcon
                            className="mr-2 w-4"
                            aria-hidden="true"
                          />
                        ) : (
                          <StarIcon className="mr-2 w-4" aria-hidden="true" />
                        )}
                        <FollowProjectButton {...follow} as="text" />
                      </div>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {follow && (
        <div className="-mr-8 mt-0 hidden self-center md:ml-4 md:mr-0 md:block">
          <FollowAction {...follow} />
        </div>
      )}
    </div>
  )
}

export default Header
