const MainWithHeaderLayout = ({ children, header }) => {
  return (
    <main className="min-h-screen flex-1 bg-secondary-50 md:ml-64">
      <header className="p-2 md:py-6 md:pl-4 md:pr-5">{header}</header>
      <section className="border-t px-2 pb-4 pt-4 md:pl-4 md:pr-5 md:pt-8">
        {children}
      </section>
    </main>
  )
}

export default MainWithHeaderLayout
