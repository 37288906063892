const ProjectRoleNameTag = ({ label }) => {
  return (
    label && (
      <span className="whitespace-nowrap rounded-full border border-secondary-300 bg-white px-2 pb-[1px] text-xxs text-secondary-400 md:text-xs">
        {label}
      </span>
    )
  )
}

export default ProjectRoleNameTag
