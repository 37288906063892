import { useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner'
import SideNavigation from 'src/components/SideNavigation'
import WelcomeBanner from 'src/components/WelcomeBanner/WelcomeBanner'
import MainWithHeaderLayout from 'src/layouts/MainWithHeaderLayout'
import ProjectNotFoundPage from 'src/pages/Organization/Project/ProjectNotFoundPage'

import ProjectHeaderCell from './subs/ProjectHeaderCell'
import ProjectNavCell from './subs/ProjectNavCell'

const QUERY = gql`
  query FindProjectForLayout($organizationSlug: String!, $slug: String!) {
    project: projectBySlug(organizationSlug: $organizationSlug, slug: $slug) {
      id
    }
  }
`

const ProjectLayout = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const { organizationSlug, slug } = useParams()
  const { data, loading } = useQuery(QUERY, {
    variables: {
      organizationSlug,
      slug,
    },
  })

  if (loading) {
    return (
      <div className="item-center flex min-h-screen flex-col justify-center bg-secondary-50">
        <div className="mx-auto flex items-center rounded bg-white p-4 text-sm text-secondary-400 shadow md:p-8">
          <LoadingSpinner size={6} />
          <span className="ml-2 ">Loading Project...</span>
        </div>
      </div>
    )
  } else if (!data?.project) {
    return <ProjectNotFoundPage />
  }

  return (
    <>
      <SideNavigation>
        <ProjectNavCell />
      </SideNavigation>
      <MainWithHeaderLayout header={<ProjectHeaderCell />}>
        <div className="mx-auto max-w-screen-lg">
          {!isAuthenticated && <WelcomeBanner />}
          {children}
        </div>
      </MainWithHeaderLayout>
    </>
  )
}

export default ProjectLayout
