import { useCallback, useContext } from 'react'

import {
  BoltIcon,
  ClockIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  TagIcon,
} from '@heroicons/react/24/solid'

import { NavLink, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { useNewPostsContext } from 'src/contexts/NewPostsContext'
import { ApplicationContext } from 'src/layouts/ApplicationLayout'

const TopLevelNav = () => {
  const { isAuthenticated } = useAuth()
  const { setSidebarOpen } = useContext(ApplicationContext)
  const { newPostsCount } = useNewPostsContext()

  const countDisplay = (type) => {
    if (newPostsCount[type] > 9) {
      return '9+'
    } else {
      return newPostsCount[type]
    }
  }

  const baseClass =
    'relative flex transform items-center rounded-r-sm bg-white py-2 pl-2 font-semibold text-primary-600 duration-150 hover:bg-primary-50'

  const LinkItem = useCallback(
    ({ label, countKey, to, Icon }) => {
      return (
        <li className="relative">
          <NavLink
            to={to}
            className={baseClass}
            activeClassName={`${baseClass} group active !text-white !bg-primary-600 hover:!bg-primary-600 -mr-1`}
            onClick={() => setSidebarOpen(false)}
          >
            <Icon className="h-6 text-primary-400 group-[.active]:text-primary-200" />
            {newPostsCount[countKey] > 0 && (
              <span className="absolute top-0 ml-4 mt-2 h-3 w-3 rounded-full bg-danger-700 text-center text-status text-white">
                {countDisplay(countKey)}
              </span>
            )}
            <span className="ml-2">{label}</span>
          </NavLink>
        </li>
      )
    },
    [newPostsCount]
  )

  return (
    <ul className="mb-4 border-b border-secondary-200 pb-4">
      {isAuthenticated && (
        <LinkItem
          label="Latest"
          countKey="latest"
          to={routes.latest()}
          Icon={ClockIcon}
        />
      )}
      {isAuthenticated && (
        <LinkItem
          label="Engage"
          countKey="engage"
          to={routes.engage()}
          Icon={ChatBubbleOvalLeftEllipsisIcon}
        />
      )}
      <LinkItem
        label="Discover"
        countKey="discover"
        to={routes.discover()}
        Icon={BoltIcon}
      />
      <LinkItem
        label="Tags"
        countKey="tags"
        to={routes.tags()}
        Icon={TagIcon}
      />
    </ul>
  )
}

export default TopLevelNav
