import { Link, routes, useParams } from '@redwoodjs/router'

const SessionLayout = ({ children }) => {
  const params = useParams()

  return (
    <div className="theme-indigo flex h-screen flex-col items-center justify-center">
      {params.redirectTo?.match('/r/') ? (
        <Link to="/redwoodjs/redwoodjs-conf-2023" className="block">
          <img
            src="/images/redirects/redwoodjsconf2023.png"
            alt="Welcome to RedwoodJS Conf!"
            className=" mb-4 w-96 "
          />
        </Link>
      ) : (
        <Link to={routes.home()} className="block">
          <img src="/images/logo.svg" alt="Spoke" className="mb-4" />
        </Link>
      )}
      {children}
    </div>
  )
}

export default SessionLayout
